import React from "react"
import Layout from "../components/layout/layout"
import { Link } from "gatsby"
import InnerWrapper from "../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFrown } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"

import SHOP_CATALOG from "../images/shop-catalog.jpg"
import READ_BLOG from "../images/read-blog.jpg"
import VIEW_LOCATIONS from "../images/view-locations.jpg"
import EXPLORE_CUSTOM from "../images/explore-custom.jpg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <InnerWrapper>
      <Col md={12} className="nfcontainer">
        {/*<h1 className="nfbig">404</h1>*/}
        <h2 className="nfheader">
          Hmm…it looks like the page you are looking for may have moved.
        </h2>
        <br />
        <h3 className="nfheader3">Here are some links you may find helpful:</h3>

        <Row className="portfolio-grid" style={{ marginTop: "30px" }}>
          <Col sm={6} md={3} className="nfitem nftext">
            <Link
              className="_link"
              to="https://shop.walpoleoutdoors.com/"
            ></Link>
            <figure
              style={{
                background: `linear-gradient(
                    rgba(21, 21, 21, 0.45), 
                    rgba(21, 21, 21, 0.45)

                  ),url(${SHOP_CATALOG}) center center/cover no-repeat`,
              }}
            ></figure>
            <h4>Shop Our Catalog</h4>
          </Col>
          <Col sm={6} md={3} className="nfitem nftext">
            <Link className="_link" to="/custom-projects"></Link>
            <figure
              style={{
                background: `linear-gradient(
                    rgba(21, 21, 21, 0.45), 
                    rgba(21, 21, 21, 0.45)
                  ),url(${EXPLORE_CUSTOM}) center center/cover no-repeat`,
              }}
            ></figure>
            <h4>Explore Custom Projects</h4>
          </Col>
          <Col sm={6} md={3} className="nfitem nftext">
            <Link className="_link" to="/blog"></Link>
            <figure
              style={{
                background: `linear-gradient(

                    rgba(21, 21, 21, 0.45), 
                    rgba(21, 21, 21, 0.45)
                  ),url(${READ_BLOG}) center center/cover no-repeat`,
              }}
            ></figure>
            <h4>Read the Blog</h4>
          </Col>
          <Col sm={6} md={3} className="nfitem nftext">
            <Link className="_link" to="/about/our-locations"></Link>
            <figure
              style={{
                background: `linear-gradient(
                    rgba(21, 21, 21, 0.45), 
                    rgba(21, 21, 21, 0.45)
                  ),url(${VIEW_LOCATIONS}) center center/cover no-repeat`,
              }}
            ></figure>
            <h4>View Our Locations</h4>
          </Col>
        </Row>
        <br />
        <h4 className="nfheader4">
          Still can't find what you're looking for?
          <br />
          Contact us directly so we can assist you!
          <br /> <a href="tel:+18003436948">(800) 343-6948</a>
        </h4>
        <br />
      </Col>
    </InnerWrapper>
  </Layout>
)

export default NotFoundPage
